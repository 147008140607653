@import "tailwindcss";
@import "tailwindcss-animated";
@plugin "daisyui";
@plugin "@tailwindcss/typography";

/* https: //github.com/saadeghi/daisyui/issues/3537 */

/* --color-base-100: oklch(24.78% 0 0);
--color-base-200: oklch(30.52% 0 0);
--color-base-300: oklch(20.46% 0 0);
--color-base-content: oklch(94.31% 0 0);
--color-primary: oklch(63.74% 0.1242 169.51);
--color-primary-content: oklch(100% 0 0); */
@plugin "daisyui/theme" {
  name: "dark";
  default: false;
  prefersdark: false;
  color-scheme: "dark";
  --muted: oklch(0.28 0.04 259.99);
  --muted-foreground: oklch(0.71 0.04 261.46);
}

/* --color-base-100: oklch(100% 0 0);
--color-base-200: oklch(96.72% 0 0);
--color-base-300: oklch(98.21% 0 0);
--color-base-content: oklch(15.91% 0 0);
--color-primary: oklch(63.74% 0.1242 169.51);
--color-primary-content: oklch(100% 0 0); */

@plugin "daisyui/theme" {
  name: "light";
  default: false;
  prefersdark: false;
  color-scheme: "light";
  --muted: oklch(0.28 0.04 259.99);
  --muted-foreground: oklch(0.71 0.04 261.46);
}

/* https://github.com/steven-tey/novel/blob/ecdb5d2ff5d3e6ab5f5af95b5a81de3ab4e8a0e9/apps/web/styles/globals.css#L4 */

html,
body,
app-root {
  /* https: //stackoverflow.com/questions/29001977/safari-in-ios8-is-scrolling-screen-when-fixed-elements-get-focus/35675094#35675094 */
  /* NOTE: USE THIS CAUSE WINDOW SCROLL STOP WORKING */
  /* -webkit-overflow-scrolling: touch !important; */
  /* overflow: auto !important; */
  height: 100%;
}

:host,
html {
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  font-feature-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, -apple-system, system-ui, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, Helvetica, Apple Color Emoji, Arial, Segoe UI Emoji, Segoe UI Symbol;
  font-variation-settings: normal;
  line-height: 1.5;
  tab-size: 4
}

ngneat-dialog .ngneat-close-dialog {
  z-index: 999;
  background-color: var(--color-base-200);
  color: var(--color-base-content);
  --dialog-close-btn-size: 32px;
  --dialog-close-btn-top: 8px;
}

ngneat-dialog .ngneat-dialog-backdrop {
  overflow: hidden !important;
}

ngneat-dialog .ngneat-dialog-backdrop .ngneat-dialog-content {
  overflow: auto !important;
  --dialog-content-bg: var(--color-base-100);
}

.bg-ribbon {
  /* --c: var(--fallback-p, oklch(var(--p))); */
  --tw-bg-opacity: 1;
  /* --c: var(--fallback-p, oklch(var(--p) / var(--tw-bg-opacity))); */
  --c: oklch(var(--btn-color) / var(--tw-bg-opacity));
  background-image: conic-gradient(from 45deg at calc(100% - 1.5em) 50%, #0000 25%, var(--c) 0);
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
}

.no-scrollbar {
  scrollbar-width: none;
}

@supports not (scrollbar-width: none) {
  .no-scrollbar {
    overflow: auto;
    -ms-overflow-style: none;
    /* Add additional styles for browsers that don't support scrollbar-width */
  }
}

.triple-truncate {
  @apply line-clamp-3 max-w-full text-balance text-[15px] leading-6
}

.double-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2em;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}